<template>
  <div>

    <BlockViewer header="Pedidos por Período"  >
      <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div class="grid">


          <div class="col-12 md:col-6 lg:col-4">
            <Calendar :showIcon="true"
                      :showButtonBar="true"
                      v-model="dataInicio"
                      placeholder="Selecione a Data Inicial"
                      dateFormat="dd/mm/yy"
                      @date-select="mudouDataFiltro()"
                      style="width: 50%"></Calendar>
            <Calendar :showIcon="true"
                      :showButtonBar="true"
                      placeholder="Selecione a Data Final"
                      v-model="dataFim"
                      dateFormat="dd/mm/yy"
                      @date-select="mudouDataFiltro()"
                      style="width: 50%"></Calendar>


            <div class="mt-3" v-if="pedidos.length > 0">
              <download-excel :data="pedidos"
                              :fields="json_export_fields"
                              name="planilha_pedidos.xls">
                Download Excel
                <i class="ml-2 pi pi-download text-blue-500 text-xl"></i>
              </download-excel>

            </div>



          </div>

          <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Valor Total Pedidos</span>
                  <div class="text-900 font-medium text-xl">R$ {{ formatDecimal(valorPedidos)}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">{{ formatCurrency(valorPedidosMes)}} este mês </span>
              <!--                            <span class="text-500">since last visit</span>-->
            </div>
          </div>

          <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Peso Total</span>
                  <div class="text-900 font-medium text-xl">{{ formatDecimal(pesoPedidos) }} Kg</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">{{ formatDecimal(pesoPedidosMes) }} Kg </span>
              <span class="text-500">este Mês</span>
            </div>
          </div>


        </div>
      </div>
    </BlockViewer>



    <DataTable ref="dt" :value="pedidos" v-model:selection="selectedPedidos" dataKey="id" :paginator="true" :rows="10"
               v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pedidos" responsiveLayout="scroll">
      <template #header>
        <div class="flex justify-content-between flex-column sm:flex-row">
          <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
          <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
        </div>
      </template>

      <Column field="id" header="ID" :sortable="true" filterField="id" :showFilterMatchModes="false" >
        <template #body="slotProps">
          <span class="p-column-title">ID</span>
          {{slotProps.data.id}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo id"/>
        </template>
      </Column>

      <Column field="codexterno" header="Código" :sortable="true" filterField="id" :showFilterMatchModes="false" >
        <template #body="slotProps">
          <span class="p-column-title">Id</span>
          {{slotProps.data.codexterno}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
        </template>
      </Column>

      <Column field="cliente" header="Cliente" :sortable="true" filterField="cliente" >
        <template #body="slotProps">
          <span class="p-column-title">Cliente</span>
          {{slotProps.data.cliente}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código cliente"/>
        </template>
      </Column>

      <Column field="nome" header="Nome" :sortable="true" filterField="nome" >
        <template #body="slotProps">
          <span class="p-column-title">Nome</span>
          {{slotProps.data.nome}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo nome do cliente"/>
        </template>
      </Column>

      <Column field="cidade" header="Cidade" :sortable="true" class="text-left" filterField="cidade">
        <template #body="slotProps">
          <span class="p-column-title">Cidade</span>
          {{formatCurrency(slotProps.data.cidade)}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
        </template>
      </Column>
      <Column field="estado" header="Estado" :sortable="true" class="text-right" filterField="estado">
        <template #body="slotProps">
          <span class="p-column-title">Estado</span>
          {{slotProps.data.estado}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
        </template>
      </Column>
      <Column field="categ" header="Categoria" :sortable="true" filterField="categ">
        <template #body="slotProps">
          <span class="p-column-title">Categoria</span>
          {{slotProps.data.categ}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela categoria"/>
        </template>
      </Column>

      <Column field="status" header="Status" :sortable="true" filterField="status">
        <template #body="slotProps">
          <span class="p-column-title">Status</span>
          <span :class="'pedido-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status"/>
        </template>
      </Column>

      <Column header="Data" dataType="date" style="min-width:10rem">
        <template #body="slotProps">
          {{formatDate(slotProps.data.dtemissao)}}
        </template>
      </Column>

      <Column field="valortotal" header="Valor" :sortable="true" class="text-right" filterField="valortotal">
        <template #body="slotProps">
          <span class="p-column-title">Valor</span>
          {{formatCurrency(slotProps.data.valor)}}
        </template>
        <template #filter="{filterModel}">
          <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
        </template>
      </Column>

      <Column header="Ações" class="text-right">
        <template #body="slotProps">

          <!--                <Button icon="pi pi-eye" class="p-button-rounded p-button-info mr-2 mb-2" @click="$router.push('pedidopreview')" />-->
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2" @click="$router.push('pedidodetalhe/' + slotProps.data.id)" />
          <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2 mb-2" @click="visibleTimeline = true" />
        </template>
      </Column>





    </DataTable>

  </div>

</template>

<script>
import BlockViewer from "@/BlockViewer";
import PedidoService from '@/service/PedidoService';
import {FilterMatchMode, FilterOperator} from "primevue/api";
import moment from 'moment'


export default {
  name: 'Free',
  components: {BlockViewer},
  data() {
    return {
      json_export_fields: {
        "ID": "id",
        "CODIGO": "codexterno",
        "CRIADO":{
          field: 'created_at',
          callback: (value) => {
            return `${this.formatDateTime(value)}`;
          }
        },
        "EMISSAO":{
          field: 'dtemissao',
          callback: (value) => {
            return `${this.formatDate(value)}`;
          }
        },
        "CLIENTE": "cliente",
        "NOME": "nome",
        "CIDADE": "cidade",
        "ESTADO": "estado",
        "VALOR":{
          field: 'valor',
          callback: (value) => {
            return `${this.formatDecimal(value)}`;
          }
        },
        "PESO":{
          field: 'peso',
          callback: (value) => {
            return `${this.formatDecimal(value)}`;
          }
        },
        "STATUS": "status",
        "REPRESENTANTE": "repres",
        "USUARIO": "created_by",
      },
      dataInicio: null,
      dataFim: null,
      valorPedidos: 0,
      valorPedidosMes: 0,

      pesoPedidos:0,
      pesoPedidosMes: 0,

      pedidos: [],
      pessoaData: [],

      selectedAutoValueCliente: null,
      autoFilteredCliente: [],
      autoValueCliente: [],

      representantes: null,
      selectedPedidos: null,
      loading1: false,
      filters1: null,
    }
  },
  pedidoService: null,
  created() {
    this.pedidoService = new PedidoService();
    this.initFilters();

  },
  mounted() {



    // this.pedidoService.getLeads().then((data) => {
    //   console.log('orcamentoColaborador mounted')
    //   this.loading1 = false
    //   this.representantes = data
    // })

  },

  methods: {
    mudouDataFiltro(){

      if (this.dataInicio && this.dataFim){

        console.log('mudouDataFiltro')
        console.log(this.dataInicio)
        console.log(this.dataFim)


        this.loading1 = true
        console.log(event.value)
        this.pessoaData = event.value
        this.valorPedidos = 0
        this.valorPedidosMes = 0
        this.pesoPedidos = 0
        this.pesoPedidosMes = 0
        var valorTotal = 0
        var valorTotalMes = 0
        var pesoTotal = 0
        var pesoTotalMes = 0

        this.pedidoService.getPedidosPediodo({
          'inicio': this.dataInicio,
          'fim': this.dataFim
        }).then((dataPedCli) => {
          console.log('getPedidosPediodo')
          console.log(dataPedCli[1])
          this.pedidos = dataPedCli[1]

          console.log('mes_atual')
          var mesAtual = moment().toDate().getMonth()
          console.log(mesAtual)

          this.pedidos.forEach((pedido) => {
            pedido.valor = parseFloat(pedido.valor)
            pedido.dtemissao = moment(pedido.dtemissao).toDate()

            console.log('pedido.dtemissao')
            console.log(pedido.dtemissao.getMonth())
            var mesPedido = pedido.dtemissao.getMonth()

            if (mesPedido === mesAtual){
              valorTotalMes = valorTotalMes + pedido.valor
              pesoTotalMes = pesoTotalMes + pedido.peso
            }

            valorTotal = valorTotal + pedido.valor
            pesoTotal = pesoTotal + pedido.peso
          });

          this.valorPedidos = valorTotal
          this.valorPedidosMes = valorTotal

          this.pesoPedidos = pesoTotal
          this.pesoPedidosMes = pesoTotal

          console.log('this.valorPedidos')
          console.log(this.valorPedidos)

          this.loading1 = false

        });
      }


    },


    selecionaCliente() {

    },


    searchCliente(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCliente = [...this.autoValueCliente];
        }
        else {
          // console.log('vai pesquisar')
          if (event.query.length >= 3){
            this.pedidoService.getPesqCliente(event.query).then((data) => {
              // console.log('getPesqCliente')
              // console.log(data)
              this.autoValueCliente = data
              this.autoFilteredCliente = this.autoValueCliente.filter((pessoa) => {
                if (pessoa.nome && event.query){
                  // console.log(pessoa.nome)
                  return pessoa.nome;
                }
              });
            });
          }
        }
      }, 250);
    },

    formatDateTime(value) {
      // console.log('value formatDate')
      // console.log(value)
      // console.log(moment())
      const format = "DD/MM/YYYY HH:mm:ss"
      var date = new Date(value);
      let dateTime = moment(date).format(format);
      // console.log(dateTime1)
      return dateTime
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if (value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },


    clearFilter1() {
      this.initFilters();
    },
  }
}
</script>
<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
